export const TIMERS_LIMITS_MANUAL_ANSWER = {
  MAX: 300,
  MIN: 2,
};

export const TIMERS_LIMITS_DESTINATION_ANSWER = {
  MAX: 90,
  MIN: 20,
};

export const TIMERS_LIMITS_AFTER_SERVICE = {
  MAX: '59:59',
  MIN: '00:01',
};

export const TIMERS_LIMITS_CLASSIFICATION = {
  MAX: '05:00',
  MIN: '00:01',
};

export const TIME_10_MIN_IN_SECONDS = 600;

export const STATUS_ERRORS = {
  DESTINATION_UNAVAILABLE: 432,
  ORIGIN_UNAVAILABLE: 433,
  BUSY_DESTINATION: 434,
  NO_EXISTING_DESTINATION: 435,
  EXTENSION_CALL_FAILURE: 436,
  ORIGIN_BUSY: 437,
  NO_WHATSAPP_SESSION_NO_HSM_TEMPLATES: 438,
  WHATSAPP_BUSY_DESTINATION: 439,
  OPERATION_NOT_ALLOWED: 405,
  INTERNAL_SERVER_ERROR: 500,
  NO_CONTENT: 204,
  CALL_LIMIT_REACHED: 451,
  AGENT_UNAVAILABLE_TO_SERVICE_CALLS: 452,
};

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEX_PATTERN = /^[a-z0-9._+-]+@[a-z0-9._-]+\.[a-z]{2,}$/;
export const ALL_SPACES_REMOVED_REGEX_PATTERN = / /g;
export const SPECIAL_CHARACTER_REGEX_PATTERN = /\W|_/;
// eslint-disable-next-line no-useless-escape
export const ALLOWS_NUMBER_SLASH_HYPHEN_REGEX_PATTERN = /^[0-9\/-]*$/;
export const START_WITH_NUMBER_REGEX_PATTERN = /^[0-9]/;
export const NUMBER_REGEX_PATTERN = /^[0-9]*$/;
export const LETTERS_SPECIAL_CHAR_REGEX_PATTERN = /[a-zA-Z\W|_]/;
export const LETTERS_REGEX_PATTERN = /[a-zA-Z]/;
export const IP_V4_REGEX_PATTERN = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
// eslint-disable-next-line no-useless-escape
export const IP_V6_REGEX_PATTERN =
  /^([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})(:|_::)([a-f0-9]{0,4})$/;
export const HOSTNAME_REGEX_PATTERN = /^[A-Za-z0-9][A-Za-z0-9-.]*\.\D{2,4}$/;
export const PHONE_NUMBER_REGEX_PATTERN = /^\+{0,1}[0-9]{0,3}\({0,1}[0-9]{2,3}\){0,1}[0-9]{0,6}-{0,1}[0-9]{1,15}$/; // Ex: 481234567890 ou (48)1234567890 ou (48)12345-67890 ou +55481234567890 ou +55(48)1234567890 ou +55(48)12345-67890

export const MAPPED_ERRORS = {
  NOT_FOUND: 'NOT_FOUND',
  NAME_ALREADY_EXISTS: 'NAME_ALREADY_EXISTS',
  MESSAGE_ASSIGNED_TO_SERVICE: 'MESSAGE_ASSIGNED_TO_SERVICE',
  CLASSIFICATION_ASSIGNED_TO_SERVICE: 'CLASSIFICATION_ASSIGNED_TO_SERVICE',
  WORK_SCHEDULE_ASSIGNED_TO_SERVICE: 'WORK_SCHEDULE_ASSIGNED_TO_SERVICE',
  ROLE_ASSIGNED_TO_USER: 'ROLE_ASSIGNED_TO_USER',
  NO_FORM_DATA: 'NO_FORM_DATA',
  STANDARD_PAUSE_FORBIDDEN: 'STANDARD_PAUSE_FORBIDDEN',
  EXTENSION_OUT_OF_RANGE: 'RAMAL_INFORMADO_FORA_DA_FAIXA',
  UNAVAILABLE_EXTENSION: 'RAMAL_INDISPONIVEL',
  EXTENSION_CREATION_FAILURE: 'FALHA_CRIACAO_RAMAL',
  PROFILE_CREATION_FAILURE: 'FALHA_CRIACAO_PERFIL',
  NO_LICENSE: 'SEM_LICENCA',
  LOGIN_ALREADY_EXISTS: 'LOGIN_ALREADY_EXISTS',
  CLOSED_BY_BLOCKED_DOMAIN: 'CLOSED_BY_BLOCKED_DOMAIN',
  SERVICE_EXTENSION_ALREADY_USED: 'SERVICE_EXTENSION_ALREADY_USED',
  OUTSIDE_SERVICE_HOURS: 'OUTSIDE_SERVICE_HOURS',
  RESOURCE_IN_USE: 'RESOURCE_IN_USE',
  DENIED_BY_CONTACT_IN_USE: 'DENIED_BY_CONTACT_IN_USE',
  DOMAIN_ALREADY_EXISTS: 'DOMAIN_ALREADY_EXISTS',
  EMAIL_CONFIG_FAILED: 'EMAIL_CONFIG_FAILED',
  PERMISSION_CREATION_FAILED: 'PERMISSION_CREATION_FAILED',
  PAUSE_CREATION_FAILED: 'PAUSE_CREATION_FAILED',
  SPECIAL_DATE_CREATION_FAILED: 'SPECIAL_DATE_CREATION_FAILED',
  WORK_SCHEDULE_CREATION_FAILED: 'WORK_SCHEDULE_CREATION_FAILED',
  ROLE_CREATION_FAILED: 'ROLE_CREATION_FAILED',
  USER_CREATION_FAILED: 'USER_CREATION_FAILED',
  AGENT_CREATION_FAILED: 'AGENT_CREATION_FAILED',
  PABX_CREATION_FAILED: 'PABX_CREATION_FAILED',
  SERVICE_CREATION_FAILED: 'SERVICE_CREATION_FAILED',
  INVALID_DOMAIN_NAME: 'INVALID_DOMAIN_NAME',
  INVALID_OP: 'INVALID_OP',
  MISSING_EMAIL_CONFIGURATION: 'MISSING_EMAIL_CONFIGURATION',
  CNPJ_SERIAL_SERVER_UNREACHABLE: 'CNPJ_SERIAL_SERVER_UNREACHABLE',
  INVALID_CNPJ_SERIAL: 'INVALID_CNPJ_SERIAL',
  LICENSE_VALIDATION_FAILED: 'LICENSE_VALIDATION_FAILED',
  DOMAIN_CREATION_FAILED: 'DOMAIN_CREATION_FAILED',
  AUTH_SERVICE_DOMAIN_DELETION_FAILED: 'AUTH_SERVICE_DOMAIN_DELETION_FAILED',
  SETTINGS_SERVICE_DOMAIN_DELETION_FAILED: 'SETTINGS_SERVICE_DOMAIN_DELETION_FAILED',
  PA_SERVICE_DOMAIN_DELETION_FAILED: 'PA_SERVICE_DOMAIN_DELETION_FAILED',
  SET_DOMAIN_STATUS_FAILED: 'SET_DOMAIN_STATUS_FAILED',
  DEFAULT_MESSAGE_CREATION_FAILED: 'DEFAULT_MESSAGE_CREATION_FAILED',
  SUPERUSER_LOGIN_ALREADY_EXISTS: 'SUPERUSER_LOGIN_ALREADY_EXISTS',
  ADMIN_LOGIN_ALREADY_EXISTS: 'ADMIN_LOGIN_ALREADY_EXISTS',
  EMAIL_ALREADY_EXISTS: 'EMAIL_ALREADY_EXISTS',
  // PARTNER_EMAIL_ALREADY_IN_USE: 'PARTNER_EMAIL_ALREADY_IN_USE',
  LOCATION_NOT_FOUND: 'LOCATION_NOT_FOUND',
  LICENSE_AGREEMENT_FAILED: 'LICENSE_AGREEMENT_FAILED',
  AGENT_OFFLINE: 'AGENT_OFFLINE',
  ORIGIN_UNAVAILABLE: 'ORIGIN_UNAVAILABLE',
  INVALID_DESTINY: 'INVALID_DESTINY',
  SOURCE_OCCUPIED: 'SOURCE_OCCUPIED',
  EXCEED_RETENTION_LIMIT: 'EXCEED_RETENTION_LIMIT',
  INVALID_CALLS_HISTORY_DATE_FILTERS: 'INVALID_CALLS_HISTORY_DATE_FILTERS',
  INVALID_LDAP_USER: 'INVALID_LDAP_USER',
};

export const PAUSE_REASON_NAME_LIMIT = 50;

export const USER_PASSWORD_LIMIT = 100; //FIXME: Limite de senha para agente muito grande

export const AGENT_NAME_LIMIT = 70;
export const AGENT_LOGIN_LIMIT = 100;
export const AGENT_EXTENSION_LIMIT = 8;
export const AGENT_MAKE_CALL_INFO = 25;
export const AGENT_MAKE_CALL_INFO_LIMITS = {
  ACCOUNT: 4,
  ACCOUNT_PASSWORD: 4,
  ROUTE: 2,
};

export const SERVICE_NAME_LIMIT = 50;
export const SERVICE_OVERFLOW_QUEUE_LIMITS = {
  MAX: 99999,
  MIN: 0,
};
export const SERVICE_VOICE_EXTENSION_LIMIT = 8;
export const SERVICE_VOICE_ACCOUNT_LIMIT = 8;
export const SERVICE_VOICE_PASSWORD_LIMIT = 8; //FIXME: Limite da senha da conta diverge do limite da conta de agente (4 caracteres)
export const SERVICE_VOICE_ROUTE_LIMIT = 8;
export const SERVICE_PROFILE_TIME_LIMITS = {
  MAX: 99999,
  MIN: 0,
};
export const SERVICE_WHEN_ESTIMATED_WAITING_TIME_EXCEEDS = {
  MAX: 99999,
  MIN: 0,
};
export const SERVICE_REPEAT_INPUT = {
  MAX: 99999,
  MIN: 30,
};
export const SERVICE_EMAIL_PORT_LIMITS = {
  MAX: 99999,
  MIN: 0,
};
export const SERVICE_EMAIL_IP_INCOMING_SERVER_LIMIT = 30;
export const SERVICE_EMAIL_IP_OUTGOING_SERVER_LIMIT = 70;
export const SERVICE_EMAIL_USER_LIMIT = 70;
export const SERVICE_EMAIL_ACCOUNT_LIMIT = 70;
export const SERVICE_EMAIL_PASSWORD_LIMIT = 30;
export const SERVICE_EMAIL_REPLY_LIMIT = 70;

export const INPUT_LOGIN_LIMIT = 100;
export const INPUT_PASSWORD_LIMIT = 100;

export const DOMAIN_NAME_LIMIT = 10;
export const DOMAIN_DESCRIPTION_LIMIT = 200;
export const DOMAIN_DESCRIPTION_ROWS_MIN = 3;
export const DOMAIN_DESCRIPTION_ROWS_MAX = 3;
export const DOMAIN_CLIENT_FANTASY_NAME_LIMIT = 80;
export const DOMAIN_CLIENT_CORPORATE_NAME_LIMIT = 80;
export const DOMAIN_NUMBER_SERIE_PABX_LIMIT = 9;
export const DOMAIN_CNPJ_CLIENT_LIMIT = 14;
export const DOMAIN_LINEBUSINESS_CLIENT_LIMIT = 80;
export const DOMAIN_ADDRESS_LIMIT = 100;
export const DOMAIN_SITE_LIMIT = 100;
export const DOMAIN_OP_LIMIT = 15;
export const DOMAIN_REQUEST_CANCEL_REASON_LIMIT = 250;
export const INPUT_DOMAIN_ADMINISTRATOR_NAME_LIMIT = 100;
export const INPUT_DOMAIN_ADMINISTRATOR_LOGIN_LIMIT = 100;
export const INPUT_DOMAIN_EMAIL = {
  EMAIL: 80,
  HOST: 80,
  USER: 80,
  PASSWORD: 80,
};
export const INPUT_DOMAIN_LICENSE = {
  HOST_ID: 30,
  PASSWORD: 30,
};

export const PABX_INPUTS_MAX_LIMITS = {
  PATH: 50,
  PORT: 99999,
  TIME_OUT: 99999999,
};

export const INPUTS_TIME_OUT_MAX_LIMITS = {
  MAX: 60000,
  MIN: 1000,
};

export const INPUTS_PORTS_MAX_LIMITS = {
  MAX: 65535,
  MIN: 1,
};

export const DB_ACCESS_MAX_LIMITS = {
  DATABASE: 60,
  USER: 100,
  PASSWORD: 25,
};

export const WEBSOCKET_PATH_WEBRTC = 'webrtc';
export const WEBSOCKET_PROTOCOLS = {
  WS: 'ws',
  WSS: 'wss',
};

export const PABX_TYPE = {
  VM: 'v',
  IP: 'o',
  TUNELADO: 't',
  CONTAINER: 'c',
};

export const PABX_WEBSOCKET = {
  PORT_DEFAULT: 443,
  PROTOCOL_DEFAULT: WEBSOCKET_PROTOCOLS.WSS,
  PATH_DEFAULT: WEBSOCKET_PATH_WEBRTC,
};

export const PABX_INFO = {
  LICENSE_SERVER_DEFAULT: 'licenca.digitro.com.br',
  TYPE_DEFAULT: PABX_TYPE.CONTAINER,
};

export const PABX_DBACCESS = {
  USER_DEFAULT: '',
  PASSWORD_DEFAULT: '',
  DATABASE_DEFAULT: '',
};

export const PABX_EMAIL = {
  EMAIL_DEFAULT: '',
  HOST_DEFAULT: '',
  PORT_DEFAULT: 0,
  SECURITY_DEFAULT: true,
  USER_EMAIL_DEFAULT: '',
  USER_PASSWORD_DEFAULT: '',
};

export const PABX_GCP = {
  VCPUS_DEFAULT: 1,
  VCPUS_MIN: 1,
  VCPUS_MAX: 32,
  MEMORY_DEFAULT: 6,
  MEMORY_MIN: 6,
  MEMORY_MAX: 128,
  SSD_MAXSIZE_DEFAULT: 20,
  SSD_MAXSIZE_MIN: 20,
  SSD_MAXSIZE_MAX: 4000,
  HDD_MAXSIZE_DEFAULT: 20,
  HDD_MAXSIZE_MIN: 20,
  HDD_MAXSIZE_MAX: 4000,
};

export const PABX_RESOURCES = {
  NEO_NUM_USERS_DEFAULT: 10,
  NEO_NUM_USERS_MIN: 5,
  NEO_NUM_USERS_MAX: 1000,
  UNA_NUM_USERS_DEFAULT: 0,
  UNA_NUM_USERS_MIN: 0,
  UNA_NUM_USERS_MAX: 1000,
  UNA_VIDEOROOM_DEFAULT: true,
  UNA_VIDEOROOMRECORD_DEFAULT: false,
};

export const NUMBER_SERIE_PABX_LIMIT = 10;

export const SKILL_NAME_LIMIT = 30;

export const SOCIAL_MEDIA_NAME_LIMIT = 100;
export const SOCIAL_MEDIA_DESCRIPTION_LIMIT = 150;
export const SOCIAL_MEDIA_TELEGRAM_BOTNAME_LIMIT = 100;
export const SOCIAL_MEDIA_TELEGRAM_TOKEN_LIMIT = 150;

export const SOCIAL_MEDIA_WHATSAPP_URL_WEBHOOK_LIMIT = 500;
export const SOCIAL_MEDIA_WHATSAPP_PHONE_LIMIT = 15;
export const SOCIAL_MEDIA_WHATSAPP_ACCOUNT_LIMIT = 100;
export const SOCIAL_MEDIA_WHATSAPP_USER_LIMIT = 50;
export const SOCIAL_MEDIA_WHATSAPP_PASSWORD_LIMIT = 100;
export const SOCIAL_MEDIA_WHATSAPP_URL_BASE_LIMIT = 150;

export const SOCIAL_MEDIA_META_PAGE_ID_LIMIT = 150;
export const SOCIAL_MEDIA_META_TOKEN_LIMIT = 300;

export const ADMINISTRATIVE_ROLE_NAME_LIMIT = 50;
export const ADMINISTRATIVE_ROLE_DESCRIPTION_LIMIT = 255;

export const CALL_CLASSIFICATION_NAME_LIMIT = 30;

export const ASSOCIATED_DATA_LENGTH_LIMIT = 100;
export const ASSOCIATED_DATA_ROW_LIMIT = 2;
export const ASSOCIATED_DATA_MAX_ROW_LIMIT = 4;

export const SPECIFIC_MESSAGES_NAME_LIMIT = 50;

export const RESPONSE_TEMPLATE_NAME_LIMIT = 30;
export const RESPONSE_TEMPLATE_CATEGORY_LIMIT = 20;

export const REGISTER_CALLBACK_QUEUE_ABANDONMENT_LIMITS = {
  MAX: 3600,
  MIN: 0,
};

export const CALLBACK_SCHEDULING_INTERVAL_LIMITS = {
  MAX: 86400,
  MIN: 0,
};

export const CALLBACK_NUMBER_ATTEMPTS_LIMITS = {
  MAX: 99,
  MIN: 0,
};

export const CALLBACK_ABANDONED_IN_QUEUE_MIN_TIME_DEFAULT_VALUE = 10;
export const CALLBACK_RETRIES_DEFAULT_VALUE = 3;
export const CALLBACK_NOT_ANSWERED_DEFAULT_VALUE = 3600;
export const CALLBACK_BUSY_DEFAULT_VALUE = 600;
export const CALLBACK_INVALID_NUMBER_DEFAULT_VALUE = 0;
export const CALLBACK_REJECT_DEFAULT_VALUE = 600;
export const CALLBACK_OTHERS_DEFAULT_VALUE = 300;

export const LOAD_CALLS_HISTORY_DEFAULT_LIMIT = 10;

export const DEFAULT_DOUGHNUT_CHART = {
  labels: [''],
  datasets: [
    {
      data: [100],
      backgroundColor: ['#CFD9E5'],
      borderColor: ['#CFD9E5'],
      borderWidth: 1,
    },
  ],
};

export const NO_CLASSIFICATION_ID = 'NO_CLASSIFICATION';

export const COMPANY = {
  SITE: 'www.digitro.com',
  LINK_URL: 'https://www.digitro.com',
};

export const COUNTRY_BRAZIL = 'Brasil';
export const LANGUAGE_PT_BR = 'pt-BR';
export const LANGUAGE_EN = 'en';

export const CALL_RIGHT_MENU = {
  CONTACTS: 'CONTACTS',
  RESPONSE_TEMPLATE: 'RESPONSE_TEMPLATE',
  HSM_TEMPLATE: 'HSM_TEMPLATE',
};

export const UPLOAD_FILES_LIMITS = {
  SIZE: 3000000,
  QUANTITY: 3,
};

export const INSTAGRAM_ACCEPTED_EXTENSIONS = {
  AUDIO: ['.acc', '.m4a', '.wav', '.mp4'],
  IMAGE: ['.png', '.jpeg'],
  VIDEO: ['.mp4', '.ogg', '.avi', '.mov', '.webm'],
};

export const RESPONSE_TEMPLATE_ACCEPTED_EXTENSIONS = {
  FILE: ['.pdf'],
  IMAGE: ['.png', '.jpeg'],
  VIDEO: ['.mp4', '.ogg', '.avi', '.mov', '.webm'],
};

export const PHONE_NUMBER_LIMIT = 25;
export const PROTOCOL_LENGTH_LIMIT = 100;

export const USERS = {
  SETTINGS: {
    DEFAULT: {
      MEDIAS: {
        CHAT: {
          MIN: 1,
          MAX: 8,
          DEFAULT: 8,
          HANDLE_MODE: 'MANUAL',
        },
        VOICE: {
          MIN: 1,
          MAX: 1,
          DEFAULT: 1,
          HANDLE_MODE: 'MANUAL',
        },
      },
    },
  },
};

export const CHAT = {
  DEFAULT: {
    SERVICES: {
      RETENTION: {
        ENABLED: true,
        ACTION_ON_TIMEOUT: 'BACK_TO_AGENT',
        WAIT_AGENT_RESPONSE: false,
        RETAIN_ON_INTERLOCUTOR_INACTIVITY: true,
        TIMERS: {
          TIMEOUT: 86400,
          WAIT_AGENT_RESPONSE: 300,
          INTERLOCUTOR_INACTIVITY: 300,
        },
      },
    },
  },
  LIMITS: {
    SERVICES: {
      RETENTION: {
        TIMERS: {
          TIMEOUT: {
            MAX: {
              DAYS: 3,
              HOURS: 23,
              MINUTES: 59,
              TOTAL_SECONDS: 345540,
            },
            MIN: {
              DAYS: 0,
              HOURS: 0,
              MINUTES: 0,
              TOTAL_SECONDS: 60,
            },
          },
          WAIT_AGENT_RESPONSE: {
            MAX: {
              DAYS: 3,
              HOURS: 23,
              MINUTES: 59,
              TOTAL_SECONDS: 345540,
            },
            MIN: {
              DAYS: 0,
              HOURS: 0,
              MINUTES: 0,
              TOTAL_SECONDS: 60,
            },
          },
          RETAIN_ON_INTERLOCUTOR_INACTIVITY: {
            DAYS: 3,
            HOURS: 23,
            MINUTES: 59,
            TOTAL_SECONDS: 345540,
          },
        },
      },
    },
  },
  OPTIONS: {
    SERVICES: {
      RETENTION: {
        ACTION_ON_TIMEOUT: ['BACK_TO_AGENT', 'HANGUP', 'BACK_TO_SERVICE_ON_AGENT_UNAVAILABLE', 'HANGUP_ON_AGENT_UNAVAILABLE'],
      },
    },
  },
};
